export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
        REGION: "eu-north-1",
        BUCKET: "ewt-notes-app-uploads"
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://swgu3e1g2j.execute-api.us-west-2.amazonaws.com/dev"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_ZJjrGc1rj",
        APP_CLIENT_ID: "45ab6qpkeg3pg67lllac5g9uod",
        IDENTITY_POOL_ID: "eu-west-1:d4b3fe9c-f33c-4433-8c73-d675ea09457c"
    }
};
